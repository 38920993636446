<template>
  <el-main class="rh-form">
    <form-parcourscliniquecreate :routes="pcRoutes" />
  </el-main>
</template>
<script>
import FormParcourscliniquecreate from "@/components/rh/formulaires/FormParcourscliniquecreate";

export default {
  components: { "form-parcourscliniquecreate": FormParcourscliniquecreate },
  computed: {
    pcRoutes() {
      return {
        toedit: {
          name: "parcourscliniqueedit",
          params: { idpc: this.$store.state.pc.parcourscliniqueLastCreatedId },
        },
        tolist: { name: "parcourscliniques" },
      };
    },
  },
};
</script>
